import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/grant-murray-logo-wide.svg';
import { GridItem, ProjectTypeGridItem } from '../components';

const LogoGridItem = ({ style }) => (
    <Link to="/" style={{ display: 'flex', ...style }}>
        <img src={logo} alt="Grant Murray" />
    </Link>
);

const colours = {
    community: '#a9d76e',
    feasibility: '#7bbd36',
    domestic: '#7ca2dc',
    residential: '#4471c5',
    commercial: '#76d1fe',
    renewal: '#3eb4fd',
};

export const componentChoice = (item, projects) => {
    if (item === 'TriangleLeftOne') {
        return <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="left" />;
    }
    if (item === 'TriangleRightOne') {
        return <GridItem image="landing-page/TRIANGLE_3_u5nvut.jpg" triangle="right" />;
    }
    if (item === 'TriangleLeftTwo') {
        return <GridItem image="landing-page/TRIANGLE_4_nzgtbb.jpg" triangle="left" />;
    }
    if (item === 'TriangleRightTwo') {
        return <GridItem image="landing-page/GREAT_EASTERN_odgs94.jpg" triangle="right" />;
    }
    if (item === 'Empty') {
        return <GridItem hide />;
    }
    if (item === 'LogoGridItem') {
        return <LogoGridItem style={{ gridColumn: 'span 2' }} />;
    }
    if (item === 'ProjectType') {
        return (
            <ProjectTypeGridItem color={colours[projects[0].node.frontmatter.category.toLowerCase()]}>
                <div />
                <h4>{projects[0].node.frontmatter.category}</h4>
            </ProjectTypeGridItem>
        );
    }
    if (item === 'GridItem') {
        const projectItem = projects.shift();
        return (
            <GridItem
                as={Link}
                image={`${projectItem.node.frontmatter.title.replace(/\s+/g, '-').toLowerCase()}/${
                    projectItem.node.frontmatter.featuredImage
                }`}
                to={projectItem.node.fields.slug}
                wrap
            >
                <div />
                <h4>{projectItem.node.frontmatter.title}</h4>
            </GridItem>
        );
    }
    return null;
};
