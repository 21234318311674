import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Media } from './Media';
import { determineProjectNumbers, determineProjectNumbersTablet } from '../helpers/determineProjects';
import { componentChoice } from '../helpers/componentChoice';
import Footer from './Footer';

import logo from '../img/grant-murray-logo-wide.svg';
import { ProjectTypeGrid, GridContainer, GridItem } from '.';

const LogoGridItem = ({ style }) => (
    <Link to="/" style={{ display: 'flex', ...style }}>
        <img src={logo} alt="Grant Murray" />
    </Link>
);

LogoGridItem.propTypes = {
    style: PropTypes.object.isRequired,
};

export const ProjectTypeTemplate = ({ projects }) => {
    const tabletProjects = [...projects];
    const desktopProjects = [...projects];
    const widescreenProjects = [...projects];
    return (
        <GridContainer>
            <Media at="mobile">
                <ProjectTypeGrid projects={[...projects]}>
                    <LogoGridItem />
                    {projects.map((project) => (
                        <GridItem
                            wrap
                            as={Link}
                            image={`${project.node.frontmatter.title.replace(/\s+/g, '-').toLowerCase()}/${
                                project.node.frontmatter.featuredImage
                            }`}
                            to={project.node.fields.slug}
                        >
                            <div />
                            <h4>{project.node.frontmatter.title}</h4>
                        </GridItem>
                    ))}
                </ProjectTypeGrid>
            </Media>
            <Media at="portrait">
                <ProjectTypeGrid projects={projects}>
                    <LogoGridItem style={{ gridColumnStart: 1, gridColumnEnd: 2 }} />
                    {projects.map((project) => (
                        <GridItem
                            wrap
                            as={Link}
                            image={`${project.node.frontmatter.title.replace(/\s+/g, '-').toLowerCase()}/${
                                project.node.frontmatter.featuredImage
                            }`}
                            to={project.node.fields.slug}
                        >
                            <div />
                            <h4>{project.node.frontmatter.title}</h4>
                        </GridItem>
                    ))}
                    <GridItem image="landing-page/CORTEN_h8oovr.jpg" triangle="top-left" />
                </ProjectTypeGrid>
            </Media>
            <Media at="tablet">
                <ProjectTypeGrid projects={tabletProjects}>
                    {determineProjectNumbersTablet(tabletProjects.length).map((item) =>
                        componentChoice(item, tabletProjects)
                    )}
                </ProjectTypeGrid>
            </Media>
            <Media at="widescreen">
                <ProjectTypeGrid projects={widescreenProjects}>
                    {determineProjectNumbers(widescreenProjects.length).map((item) =>
                        componentChoice(item, widescreenProjects)
                    )}
                </ProjectTypeGrid>
            </Media>
            <Media greaterThanOrEqual="fullhd">
                <ProjectTypeGrid projects={desktopProjects}>
                    {determineProjectNumbers(desktopProjects.length).map((item) => componentChoice(item, desktopProjects))}
                </ProjectTypeGrid>
            </Media>
            <Footer />
        </GridContainer>
    );
};

ProjectTypeTemplate.propTypes = {
    projects: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
                frontmatter: PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    description: PropTypes.string.isRequired,
                    category: PropTypes.string.isRequired,
                    featuredImage: PropTypes.string.isRequired,
                    heroImages: PropTypes.arrayOf(
                        PropTypes.shape({
                            image: PropTypes.string,
                            title: PropTypes.string,
                        })
                    ),
                }),
                fields: PropTypes.shape({
                    slug: PropTypes.string.isRequired,
                }),
            }),
        })
    ),
};
