export const determineProjectNumbersWidescreen = (numberOfProjects) => {
    switch (numberOfProjects) {
        case 5:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 6:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 7:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 8:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 9:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'GridItem',
                'Empty',
                'Empty',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 10:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'GridItem',
                'ProjectType',
                'LogoGridItem',
                'GridItem',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 11:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'GridItem',
                'ProjectType',
                'LogoGridItem',
                'GridItem',
                'Empty',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 12:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'LogoGridItem',
                'ProjectType',
                'Empty',
                'Empty',
                'Empty',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        default:
            return [];
    }
};

export const determineProjectNumbersTablet = (numberOfProjects) => {
    switch (numberOfProjects) {
        case 5:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 6:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 7:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 8:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 9:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'GridItem',
                'Empty',
                'Empty',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 10:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'GridItem',
                'ProjectType',
                'LogoGridItem',
                'GridItem',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 11:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'GridItem',
                'ProjectType',
                'LogoGridItem',
                'GridItem',
                'Empty',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        case 12:
            return [
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'LogoGridItem',
                'ProjectType',
                'Empty',
                'Empty',
                'Empty',
                'GridItem',
                'GridItem',
                'GridItem',
            ];
        default:
            return [];
    }
};

export const determineProjectNumbers = (numberOfProjects) => {
    switch (numberOfProjects) {
        case 5:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'GridItem',
                'LogoGridItem',
                'Empty',
                'Empty',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'TriangleRightTwo',
            ];
        case 6:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'GridItem',
                'LogoGridItem',
                'Empty',
                'Empty',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'TriangleRightTwo',
            ];
        case 7:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'GridItem',
                'LogoGridItem',
                'Empty',
                'Empty',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightTwo',
            ];
        case 8:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'GridItem',
                'LogoGridItem',
                'Empty',
                'Empty',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightTwo',
            ];
        case 9:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'GridItem',
                'LogoGridItem',
                'Empty',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightTwo',
            ];
        case 10:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'GridItem',
                'ProjectType',
                'GridItem',
                'LogoGridItem',
                'Empty',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightTwo',
            ];
        case 11:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'GridItem',
                'ProjectType',
                'GridItem',
                'LogoGridItem',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightTwo',
            ];
        case 12:
            return [
                'TriangleLeftOne',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightOne',
                'Empty',
                'Empty',
                'Empty',
                'Empty',
                'ProjectType',
                'LogoGridItem',
                'Empty',
                'Empty',
                'Empty',
                'TriangleLeftTwo',
                'GridItem',
                'GridItem',
                'GridItem',
                'TriangleRightTwo',
            ];
        default:
            return [];
    }
};
